<template>
  <div id="metsoembed">
    <Krpano />
    <slot />
    <div class="askformore" @click="toggleFullScreen()">
      <img v-if="isFullScreen" src="@/assets/img/fullscreen_exit.svg" />
      <img v-else src="@/assets/img/fullscreen.svg" />
    </div>
    <button v-if="!isLoaded && !isNt" class="loadformore" @click="loadModel()">
      {{ $t("general.view_in_3d") }}
    </button>
    <div id="rotate-smartphone"></div>
  </div>
</template>

<script setup>
const isFullScreen = ref(false);
const isLoaded = ref(false);
const route = useRoute();

const loadModelState = useLoadModel();

const isNt = computed(() => route.path.includes("/nordtrack"));

onMounted(() => {
  const events = [
    "fullscreenchange",
    "webkitfullscreenchange",
    "mozfullscreenchange",
    "msfullscreenchange",
  ];
  const base = document.createElement("base");
  base.target = "_blank";
  document.head.appendChild(base);
  events.forEach((event) =>
    document.addEventListener(event, fullscreenchanged)
  );
});

function fullscreenchanged() {
  isFullScreen.value = document.fullscreenElement;
}

function loadModel() {
  loadModelState.value = true;
  // this.$root.$emit("loadModel", true); // Note: Check global event handling in Nuxt 3
  isLoaded.value = true;
}

function toggleFullScreen() {
  const element = document.getElementById("metsoembed");
  const requestMethod =
    element.requestFullScreen ||
    element.webkitRequestFullscreen ||
    element.webkitRequestFullScreen ||
    element.mozRequestFullScreen ||
    element.msRequestFullscreen;
  if (document.fullscreenElement) {
    document.exitFullscreen();
  } else {
    requestMethod?.call(element);
  }
}
</script>

<style lang="scss">
.askformore {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: #fff;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    display: block;
  }
}

.loadformore {
  background-color: #eb2814;
  color: #000000;
  border: 0;
  padding: 10px 10px;
  position: absolute;
  bottom: 40%;
  margin-bottom: -22.5px;
  left: 50%;
  margin-left: -90px;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  min-width: 180px;
  height: 45px;
  text-transform: uppercase;
}

:-webkit-full-screen #myvideo {
  width: 100%;
  height: 100%;
}

#rotate-smartphone {
  background-color: rgba(0, 0, 0, 0.8);
  background-image: url("/rotate-smartphone.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
}

/* only when orientation is in portrait mode */
@media only screen and (orientation: portrait) and (max-width: 600px) {
  #rotate-smartphone {
    display: block;
  }
}
</style>
